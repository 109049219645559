@font-face {
  font-family: hzgb;
  src: url("./fonts/hzgb.ttf");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/Montserrat-Light.ttf");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/Montserrat-Medium.ttf");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/Montserrat-Bold.ttf");
}

.App {
  color: #1d1f75;
  background-color: #eaeeff;
  min-height: 100vh;
  width: 100vw;
  font-size: 16px;
  font-family: Montserrat;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

h3 {
  color: #2e4765;
  text-align: center;
  font-size: 25px;
  font-weight: 500;
  line-height: 0;
}

h6 {
  margin-top: 40px;
  font-weight: 100;
  text-align: center;
  font-size: 20px;
  line-height: 0;
  color: #7779ac;
}

a {
  color: #1195f1;
  text-decoration: none;
}

.App-header {
  border-bottom: 1px solid #e9eff1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: 0 3px 25px rgba(0, 0, 0, 0.16);
  background: #fff;
}

.headerLayout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  padding: 0.625em 0;
}

@media only screen and (max-width: 750px) {
  .headerLayout {
    width: 100%;
    padding: 0.625em 1rem;
  }
  .titleBlock {
    margin: 1rem 1rem !important;
  }
  .titleBlock h3 {
    line-height: 26px;
  }
  .webDesc {
    line-height: 20px !important;
  }
  .blueButton {
    display: none;
  }
  .dataFeedsLayout {
    width: 100vw !important;
    padding: 1rem !important;
  }
  .dataTableLayout {
    width: 100% !important;
  }
  .dataTableHeader {
    width: 92%;
    margin: 0 auto !important;
    padding: 0.625rem 0;
    display: block !important;
    flex-direction: column !important;
    align-items: flex-start !important;
  }
  .headerSearch {
    margin: 10px 0;
  }
  .filter {
    justify-content: flex-start !important;
    column-gap: 15px;
  }
  .filter div {
    margin-left: 0 !important;
  }
  .filter div span {
    margin-left: 0 !important;
  }
  .undulation {
    font-size: 20px !important;
  }
  .scorePanel {
   padding: 1rem 0.5rem !important;
  }
  /*.scoreItem {*/
  /*  display: none !important;*/
  /*}*/
  .scorePanelContent {
    flex-wrap: wrap;
    justify-content: center !important;
    row-gap: 10px;
    text-align: center;
  }
  .dataTable {
    column-gap: 5px !important;
    padding: 0 4px;
  }
  .dataCellLayout {
    width: 180px !important;
  }
  .footerLayout {
    flex-direction: column;
    row-gap: 20px;
  }
  .footerLayout .desc {
    margin-left: 0 !important;
  }
  .pairLayout {
    width: 96% !important;
  }
  .pairLayout .infoPanel {
    padding: 2em 1em !important;
    width: 100% !important;
  }
  .infoPanelBelow {
    width: 100% !important;
  }
  .pairLayout .infoPanel .gettingError{
    padding: 0 0.5em !important;
  }
  .infoContent {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
  .bigPrice {
    margin-top: 0 !important;
  }
  .infoContent .labels {
    margin-top: 10px;
    justify-content: flex-start !important;
  }
  .bottonWithBorder {
    padding: 0 !important;
  }
  .infoPanelBelow {
    padding: 2em 1em !important;
  }
  .resource {
    column-gap: 0 !important;
    flex-direction: initial !important;
  }
  .historyPanel {
    width: 100% !important;
  }
  .tables {
    width: 100% !important;
  }
}

.headerLayout img {
  height: 2em;
  cursor: pointer;
}

.headerRight {
  color: #1295f0;
}

.headerRight .homeButton, .switchButton {
  cursor: pointer;
}

.popoverContentItem {
  padding: 6px 0;
}

.popoverContentItem Button {
  border: none;
  min-width: 150px;
  text-align: left;
}

.defaultApi {
  color: #adadad;
}

.selectWithoutBorder {
  border: none;
  outline: none;
  background: transparent;
  font-weight: 400;
  color: #1295f0;
}

.blueButton {
  background: #1195f1;
  border: 0px solid #000000;
  font-size: 1em;
  border-radius: 2em;
  padding: 0.3em 1em;
  color: white;
  outline: none;
  border: none;
}

.dataFeedsLayout {
  width: 80%;
  padding: 3em 10em;
  margin: 0 auto;
  background-image: url("./assets/graphic.png");
}

.titleBlock {
  margin: 3em 3em;
}

.webDesc {
  margin-top: 40px;
  text-align: center;
  line-height: 0;
  color: #7779ac;
}

.scorePanel {
  background: #fff;
  color: #2e4765;
  border-radius: 1em;
  padding: 1em 1.5em;
  width: 100%;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.08);
}

.scorePanelContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.scoreTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scoreTitle .logo {
  position: relative;
}

.scoreTitle .loading {
  position: absolute;
  left: 0;
  transform: scale(1.2);
  animation: loading 2s linear 0s infinite;
}

@keyframes loading {
  from {
    transform: scale(1.2) rotate(0deg);
  }
  to {
    transform: scale(1.2) rotate(360deg);
  }
}

.arsPriceWrapper {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.arsPriceTitle {
  margin-right: 0.5em;
  font-weight: 400;
  font-size: 0.5em;
}

.arsPrice {
  font-weight: 400;
  font-size: 1.4em;
}

.priceUnit {
  font-size: 0.6em;
}

.scoreItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.scoreItem div:first-child {
  font-size: 0.5em;
  font-weight: bold;
  text-transform: capitalize;
}

.scoreItem div:last-child {
  font-size: 1em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.undulationWrapper {
  color: #b24b4b;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.undulationWrapperUp {
  color: #55aa55;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.undulation {
  font-size: 30px;
  font-weight: bold;
}

.dataTableLayout {
  margin: 1em auto;
  width: 80%;
}

.dataTableHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  column-gap: 50px;
  padding-right: 30px;
}

.HomeContent {
  width: 100%;
  min-height: calc(100vh - 56px - 205px);
}

.headerSearch {
  flex: 1;
  font-weight: 400;
}

.headerSearch .ant-input-affix-wrapper {
  border: 2px solid #D4D8EA;
  border-radius: 10px;
}

.headerSearch .ant-select .ant-select-selector {
  border: 2px solid #D4D8EA;
  border-radius: 10px;
}

.dataTableHeader .filter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 400;
}

.dataTableHeader .filter div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1em;
}

.dataTableHeader .filter div span {
  margin-left: 0.5em;
  text-transform: capitalize;
}

.pairActive {
  width: 16px;
  height: 16px;
  background: #00bf78;
  border: 3px solid #bbf3de;
  border-radius: 50%;
}

.pairUnreceived {
  width: 16px;
  height: 16px;
  background: #cf2030;
  border: 3px solid #f5cdd1;
  border-radius: 50%;
}

.dataTable {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  row-gap: 1em;
  column-gap: 1em;
  margin-top: 1em;
}

.dataCellLayout {
  border: 1px solid #e1e8eb;
  background-color: #fff;
  border-radius: 8px;
  padding: 1em;
  width: 270px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: #1d1f75;
}

.dataCellLayout .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.dataCellLayout .title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.5em;
  text-transform: uppercase;
}

.dataCellLayout .price {
  font-size: 1.5em;
  font-weight: bold;
  padding-left: 0.3em;
}

.dataCellLayout .resourceLabels {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dataCellLayout .resourceLabels .tinyTitle {
  font-size: 0.6em;
  color: #7779ac;
}

.dataCellLayout .resourceLabels .resourcesLine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  row-gap: 0.5em;
  margin-top: 0.5em;
}

.dataCellLayout .resourceLabels .resources {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.dataCellLayout .resourceLabels .resourcesLine img {
  height: 1em;
  margin-right: 0.25em;
}

.dataCellLayout .resourceLabels .resourcesLine .exchangeIcon {
  /*filter: grayscale(100%);*/
}

.footerLayout {
  margin-top: 40px;
  width: 100%;
  padding: 3em 8% 3em 7%;
  font-size: 0.8em;
  background: #041a77;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.desc {
  max-width: 500px;
  text-align: center;
  margin-left: 120px;
}

.copyright {
  width: 100%;
  text-align: center;
  font-size: 0.7em;
  padding: 1em 0;
  background: #041a77;
  color: #fff;
}

.footerLayout .medias {
  display: flex;
  width: auto;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  column-gap: 1.3em;
}

.pairLayout {
  width: 80%;
  margin: 2em 10% 5em 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.pairLayout .back {
  background: #f8fafb;
  border: 1px solid #e1e8eb;
  border-radius: 6px;
  font-size: 0.8em;
  padding: 0.8em 2em;
  color: #1d1f75;
}

.pairLayout .infoPanel {
  background: #f8fafb;
  border: 1px solid #e1e8eb;
  border-radius: 8px 8px 0px 0px;
  margin-top: 2em;
  padding: 2em;
  width: calc(100% - 4em);
}

.pairLayout .infoPanel .infoTitleBar {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.pairLayout .infoPanel .infoTitleBar .tokenTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5em;
  text-transform: uppercase;
}

.pairLayout .infoPanel .infoTitleBar .gettingError {
  border: 2px solid #1295F0;
  border-radius: 25px;
  font-size: 0.8em;
  color: #1295F0;
  padding: 0.5em 1em;
  display: flex;
  align-items: center;
  column-gap: 0.5em;
}


.gettingError:hover {
  cursor: pointer;
}

.pairLayout .infoPanel .infoContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 2em;
}

.pairLayout .infoPanel .infoContent .bigPriceLabel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.pairLayout .infoPanel .infoContent .bigPriceLabel .title {
  font-size: 0.7em;
  color: #7779ac;
  text-transform: capitalize;
}

.pairLayout .infoPanel .infoContent .bigPriceLabel .bigPrice {
  font-size: 2em;
  margin-top: 0.6em;
}

.pairLayout .infoPanel .infoContent .labels {
  display: flex;
  flex-direction: row;
  width: auto;
  justify-content: flex-end;
  align-items: flex-end;
  column-gap: 1em;
}

.pairLayout .infoPanel .infoContent .labels .labelAndValue {
  display: flex;
  flex-direction: column;
  width: auto;
  justify-content: flex-end;
  align-items: flex-end;
}

.pairLayout .infoPanel .infoContent .labels .labelAndValue div:first-child {
  font-size: 0.7em;
  color: #7779ac;
  text-transform: capitalize;
}

.pairLayout .infoPanel .infoContent .labels .labelAndValue div:last-child {
  font-size: 1em;
  color: #1d1f75;
  margin-top: 0.5em;
}

.pairLayout .infoPanelBelow {
  background: white;
  border: 1px solid #e1e8eb;
  border-top: none;
  border-radius: 0 0 8px 8px;
  margin-top: 0;
  padding: 2em;
  width: calc(100% - 4em);
}

.pairLayout .infoPanelBelow .infoTitleBar {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
}

.pairLayout .infoPanelBelow .infoTitleBar .bottonWithBorder {
  background: #1295F0;
  outline: none;
  border: 1px solid #1195f1;
  border-radius: 2em;
  padding: 0.5em 1.2em;
  font-size: 16px;
  color: #FFF;
  text-transform: capitalize;
}

.pairLayout .infoPanelBelow .infoContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  column-gap: 2em;
  margin-top: 2em;
}

.ResourceLabelLayout {
  padding: 1em 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 0.8em;
  width: 8em;
}

.ResourceLabelLayout .title {
  text-transform: capitalize;
  font-size: 0.8em;
}

.ResourceLabelLayout .price {
  font-size: 1em;
}

.ResourceLabelLayout .weight {
  font-size: 0.7em;
  color: #7779ac;
  text-transform: capitalize;
}

.pairLayout .historyPanel {
  background: #ffffff;
  border: 1px solid #e1e8eb;
  border-radius: 8px;
  padding: 2em 1em;
  width: calc(100% - 4em);
  margin-top: 2em;
}

.pairLayout .historyPanel .infoTitleBar {
  text-transform: capitalize;
  padding-left: 1em;
  margin-bottom: 1em;
}

.pairLayout .tables {
  width: calc(100% - 4em);
  margin-top: 2em;
}

.pairLayout .tables .title {
  text-align: left;
  text-transform: capitalize;
}

.pairLayout .tables .tabBar {
  display: flex;
  flex-direction: row;
  column-gap: 2em;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 0.8em;
  margin-top: 2em;
}

.pairLayout .tables .tabBar div {
  cursor: pointer;
  text-transform: capitalize;
}

.pairLayout .tables .tabBar .selected {
  color: #1d1f75;
  border-bottom: 1px solid #1d1f75;
  padding-bottom: 1em;
}

.pairLayout .tables .tabBar .unselected {
  color: #7779ac;
}

.pairLayout .tables table {
  width: 100%;
  margin-top: 2em;
  border-collapse: separate;
  border-spacing: 0;
}

.pairLayout .tables table thead tr th {
  text-transform: uppercase;
  font-size: 0.8em;
  color: #7779ac;
  text-align: left;
  padding: 1em;
  background: #f8fafb;
  border-top: 1px solid #e1e8eb;
  border-bottom: 1px solid #e1e8eb;
}

.pairLayout .tables table thead tr th:first-child {
  border-radius: 8px 0 0 0;
  border-left: 1px solid #e1e8eb;
}

.pairLayout .tables table thead tr th:last-child {
  border-radius: 0 8px 0 0;
  border-right: 1px solid #e1e8eb;
}

.pairLayout .tables table tbody tr td {
  border-bottom: 1px solid #e1e8eb;
  background-color: white;
  padding: 1.5em 1em;
  font-size: 0.8em;
}

.pairLayout .tables table tbody tr td .buttonWithoutBorder {
  background-color: white;
  border: none;
  outline: none;
  font-family: hzgb;
  padding: 0.25em 1em;
  cursor: pointer;
}

.pairLayout .tables table tbody tr td div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.25em;
  flex-direction: row;
}

.pairLayout .tables table tbody tr td:first-child {
  border-left: 1px solid #e1e8eb;
}

.pairLayout .tables table tbody tr td:last-child {
  border-right: 1px solid #e1e8eb;
  width: 3em;
}

.modalLayout {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalLayout .modalContent {
  box-shadow: 30px 30px 80px rgba(0, 0, 0, 0.5);
  pointer-events: all;
  background: #ffffff;
  border-radius: 12px;
  padding: 3em 2em;
  position: relative;
}

.modalLayout .modalContent .closeButton {
  position: absolute;
  right: 1em;
  top: 1em;
  z-index: 1;
  background-color: transparent;
  border: none;
  outline: none;
}

.backUP {
  width: 38px;
  height: 38px;
  background: #2e4dd4;
  box-shadow: 0 7px 24px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  
  to {
    transform: rotate(360deg);
  }
} */
